import React from "react";
import { graphql } from "gatsby";
import Header from "Commons/Header";
import Footer from "Commons/Footer";
import InternalTrainingPage from "Components/Trainings/InternalTrainingPage";
import { Helmet } from "react-helmet";
import useLang from "Commons/Hooks/useLang";

const WebHackingTrainingPage = ({ data, location }) => {
  const [lang] = useLang();
  const { webHackingPageTitle, webHackingTitle, webHackingDescription } =
    data.allContentfulTrainingTexts.nodes[lang];

  return (
    <>
      <Helmet>
        <title>{webHackingPageTitle}</title>
      </Helmet>
      <Header noScrollEffect />
      <InternalTrainingPage
        title={webHackingTitle}
        richText={webHackingDescription}
        currentTraining={location.pathname}
      />
      <Footer />
    </>
  );
};

export const query = graphql`
  query getWebHackingTrainingPageText {
    allContentfulTrainingTexts {
      nodes {
        webHackingPageTitle
        appsecForDevelopersTitle
        appsecForDevelopersSynopsis {
          appsecForDevelopersSynopsis
        }
        webHackingTitle
        webHackingDescription {
          raw
        }
        dominatingBurpSuiteTitle
        dominatingBurpSuiteSynopsis {
          dominatingBurpSuiteSynopsis
        }
      }
    }
  }
`;

export default WebHackingTrainingPage;
